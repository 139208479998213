<template>
  <ElDialog
    id="plan-modification-succeed-dialog"
    :center="true"
    :visible.sync="isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div
      v-if="isVisible"
      class="text-center"
    >
      <div class="text-center mb-3">
        <div
          class="bg-primary rounded-circle d-inline-flex justify-content-center align-items-center"
          style="width: 64px; height: 64px"
        >
          <i class="sdicon-check text-white font-40" />
        </div>
      </div>

      <div class="font-21 mb-2 font-weight-bold">
        <template v-if="plan">
          Switched to the {{ plan.name.capitalize() }} plan.
        </template>
        <template v-else>
          Plan cancelled.
        </template>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';

export default {
  name: 'SubscriptionChangeSuccessDialog',
  setup(props, context) {
    const { $router, $route } = context.root;

    const isVisible = ref(false);
    const plan = ref();

    watch(() => isVisible.value, watchIsVisible);

    return {
      isVisible,
      plan,
      open,
    };

    async function open(data) {
      plan.value = data ? data.plan : null;
      isVisible.value = true;
    }

    function watchIsVisible() {
      if (isVisible.value) {
        return;
      }

      if ($route.name === 'app.billing') {
        window.location.reload();
      }

      $router.replace({ name: 'app.billing' }, () => {
        window.location.reload();
      });
    }
  },
};
</script>

<style lang="scss"></style>
