export const CreditRechargeAmounts = [
  {
    label: '$500',
    value: 500,
  },
  {
    label: '$1,000',
    value: 1000,
  },
  {
    label: '$2,000',
    value: 2000,
  },
];

export const CreditRechargeThreshold = 200;

export const MaxCreditBalance = 2000;
