<template>
  <ElDialog
    id="add-business-credits-dialog"
    :center="true"
    :close-on-click-modal="false"
    :fullscreen="$viewport.lt.md"
    :show-close="false"
    :visible.sync="reactiveVariables.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div class="row justify-content-center gutters-2">
      <template>
        <div class="row justify-content-center align-items-center font-weight-strong font-21">
          <template v-if="reactiveVariables.creditSuccess === true">
            Credit Added Successfully
          </template>
          <template v-else-if="reactiveVariables.creditSuccess === false">
            Cannot Process Credit
          </template>
          <template v-else>
            Add Credit
          </template>
        </div>
        <div class="col-12 font-17">
          <template v-if="reactiveVariables.creditSuccess === true">
            <strong>${{ withCommas(reactiveVariables.rechargeAmount) }}</strong>
            succesfully charged from your payment method (ending with {{ last4 }}).
            <div
              class="mt-5 text-center"
            >
              Your current balance is ${{ withCommas(businessCreditsBalance) }}
            </div>
          </template>
          <template v-else-if="reactiveVariables.creditSuccess === false">
            <ElAlert
              type="warning"
              show-icon
              :closable="false"
              class="p-2 m-3"
            >
              <div>
                <div class="font-weight-strong pl-1">
                  The payment attempt of ${{ withCommas(reactiveVariables.rechargeAmount) }} failed.
                </div>
                <div class="mt-4 pl-1">
                  Please ensure your payment method is valid and try again
                </div>
              </div>
            </ElAlert>
          </template>
          <template v-else>
            <span>
              Your payment method will be charged for this amount and it will be added
              towards your account balance.
            </span>
            <span>Future charges for Showdigs services will use this balance first.</span>
          </template>
        </div>
        <div
          v-if="reactiveVariables.creditSuccess === null"
          class="col-md-6 col-12"
        >
          <SdFloatLabel label="Credit to add">
            <ElSelect
              v-model="reactiveVariables.rechargeAmount"
              placeholder="Credit to add"
            >
              <ElOption
                v-for="option in CreditRechargeAmounts"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </ElSelect>
          </SdFloatLabel>
        </div>
      </template>
    </div>
    <div slot="footer">
      <div
        :class="{'justify-content-center align-items-center': reactiveVariables.creditSuccess === null}"
      >
        <el-button
          :class="$viewport.lt.md ? 'w-100 mb-2' :''"
          size="medium"
          @click="reactiveVariables.isVisible = false"
          @click.stop
        >
          {{ reactiveVariables.creditSuccess === null ? 'Cancel' : 'Close' }}
        </el-button>
        <el-button
          v-if="reactiveVariables.creditSuccess === null"
          :class="$viewport.lt.md ? 'w-100 ml-0' :''"
          :disabled="!reactiveVariables.rechargeAmount || loadingRef"
          :loading="loadingRef"
          size="medium"
          type="primary"
          @click="addCredit"
          @click.stop
        >
          Add Credit
        </el-button>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { reactive, ref, computed } from '@vue/composition-api';
import { CreditRechargeAmounts } from '@/constants/Billing';
import { withCommas } from '@/utils/NumberUtil';

export default {
  name: 'AddBusinessCreditsDialog',
  setup(props, context) {
    const reactiveVariables = reactive({
      isVisible: false,
      rechargeAmount: null,
      creditSuccess: null,
    });
    const last4 = computed(() => context.root.$store.getters['Billing/cardLast4']);
    const businessCreditsBalance = computed(() => context.root.$store.getters['Business/businessCreditsBalance']);
    const loadingRef = ref(false);

    return {
      loadingRef,
      reactiveVariables,
      open,
      addCredit,
      withCommas,
      last4,
      businessCreditsBalance,
      CreditRechargeAmounts,
    };

    async function open() {
      reactiveVariables.isVisible = true;
      reactiveVariables.rechargeAmount = null;
      reactiveVariables.creditSuccess = null;
    }

    async function addCredit() {
      loadingRef.value = true;

      try {
        const updatedBusinessCreditsBalance = await context.root.$store.dispatch('Billing/addBusinessCredits', reactiveVariables.rechargeAmount);
        context.root.$store.commit('Business/setBusinessCreditsBalance', updatedBusinessCreditsBalance);

        reactiveVariables.creditSuccess = true;
      } catch (error) {
        reactiveVariables.creditSuccess = false;
      }

      loadingRef.value = false;
    }
  },
};
</script>
