<template>
  <ElDialog
    id="modify-plan-dialog"
    :center="true"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <template v-if="uiFlags.isVisible">
      <div slot="title">
        <h2 class="mb-1">
          Cancel subscription
        </h2>
      </div>
      <div class="row justify-content-center gutters-2">
        <div class="col-12">
          You are about to cancel your subscription. If you cancel, you will still be able to use Showdigs {{ resolveSubscriptionTypeText() }}
          <strong>{{ $momentUtil(cancelDate, $momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear() }}</strong>
        </div>
        <div class="col-12">
          On that date, all your listings will be stopped and all planned tours and condition reports will be cancelled.
        </div>
        <div
          v-if="subscription.active_units_count || subscription.open_inspections_count"
          class="col-12 text-red"
        >
          You currently have:
          <ul>
            <li v-if="subscription.active_units_count">
              {{ subscription.active_units_count }} active listings
            </li>
            <li v-if="subscription.open_inspections_count">
              {{ subscription.open_inspections_count }} planned condition reports
            </li>
          </ul>
        </div>
      </div>
      <div
        slot="footer"
        class="row gutter-1 justify-content-center"
      >
        <div class="col-md-4 col">
          <ElButton
            class="w-100"
            size="medium"
            @click="uiFlags.isVisible = false"
          >
            Don't Cancel
          </ElButton>
        </div>
        <div class="col-md-6 col">
          <ElButton
            class="w-100"
            size="medium"
            type="primary"
            :disabled="uiFlags.isButtonLoading"
            :loading="uiFlags.isButtonLoading"
            @click="cancelPlan"
          >
            Cancel Subscription
          </ElButton>
        </div>
      </div>
    </template>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';

export default {
  name: 'CancelPlanDialog',
  components: { },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { $store } = context.root;

    const uiFlags = reactive({
      isVisible: false,
      isButtonLoading: false,
    });
    const subscription = props.subscription;
    const cancelDate = subscription.is_on_contract ? subscription.contract_ends_at : subscription.current_period_end_at;

    return {
      uiFlags,
      cancelPlan,
      open,
      resolveSubscriptionTypeText,
      cancelDate,
    };

    function resolveSubscriptionTypeText() {
      if (this.subscription.is_on_contract) {
        return 'until your contract ends on';
      }
      return 'until the current billing cycle ends on';
    }

    async function open() {
      uiFlags.isVisible = true;
    }

    async function cancelPlan() {
      if (uiFlags.isButtonLoading) {
        return;
      }

      try {
        uiFlags.isButtonLoading = true;
        await $store.dispatch('Subscription/cancel');
        uiFlags.isVisible = false;
        context.emit('cancelled');
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isButtonLoading = false;
      }
    }
  },
};
</script>

<style lang="scss">
</style>
