<template>
  <ElDialog
    id="modify-plan-dialog"
    :center="true"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <template v-if="uiFlags.isVisible">
      <div slot="title">
        <h2 class="mb-1">
          Modify plan
        </h2>
      </div>
      <div
        class="row justify-content-center gutters-2"
        :class="uiFlags.isLoading ? 'align-items-center h-100' : ''"
      >
        <div
          v-if="uiFlags.isLoading"
          class="col-12 text-center"
        >
          <ElSpinner color="primary" />
        </div>
        <template v-else>
          <div class="col-12">
            You are currently on the <strong class="capitalize">{{ subscription.plan.name }}</strong> plan which is being phased out.
            You can stay on your current plan until further notice, or switch to our new plan. <a
              href="https://www.showdigs.com/pricing"
              target="_blank"
            >Learn more about our current pricing here</a>
          </div>
          <template v-if="subscription.plan.price > 0">
            <div class="col-12">
              If you choose to switch plans, you will remain on the
              <span class="capitalize">{{ subscription.plan.name }}</span>
              plan until the next billing period on
              <strong>{{ $momentUtil(subscription.current_period_end_at, $momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear() }}</strong>.
            </div>
            <div class="col-12">
              Afterwards, you will be charged <strong>${{ newPlan.price }}</strong> {{ newPlan.period }}.
            </div>
          </template>
          <template v-else>
            <div class="col-12">
              Switching to our new plan will take effect immediately and you will be charged
              <strong>${{ newPlan.price }}</strong> on a {newPlan.period}} basis.
            </div>
          </template>
        </template>
      </div>
      <div
        slot="footer"
        class="row gutter-1 justify-content-center"
      >
        <div class="col-md-4 col">
          <ElButton
            class="w-100"
            size="medium"
            @click="uiFlags.isVisible = false"
          >
            Cancel
          </ElButton>
        </div>
        <div class="col-md-6 col">
          <ElButton
            class="w-100"
            size="medium"
            type="primary"
            :disabled="uiFlags.isLoading || uiFlags.isButtonLoading"
            :loading="uiFlags.isButtonLoading"
            @click="modifyPlan"
          >
            Switch to new plan
          </ElButton>
        </div>
      </div>
    </template>
  </ElDialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { getPlanPeriod } from '@/utils/PlanUtil';

export default {
  name: 'ModifyPlanDialog',
  components: { },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { $store } = context.root;

    const uiFlags = reactive({
      isVisible: false,
      isLoading: false,
      isButtonLoading: false,
    });
    const newPlan = ref();

    if (context.root.$route.query.modify) {
      open();
    }
    return {
      uiFlags,
      newPlan,
      modifyPlan,
      open,
      Plan,
    };

    async function open() {
      uiFlags.isVisible = true;
      uiFlags.isLoading = true;

      try {
        // only non-standard plans can be modified, they're modified to the monthly standard plan
        newPlan.value = (await $store.dispatch('Plan/get')).find((plan) => plan.name === Plan.MONTHLY_STANDARD);
        newPlan.value.period = getPlanPeriod(newPlan.value);
        uiFlags.isLoading = false;
      } catch (error) {
        uiFlags.isVisible = false;
        showErrorNotify(context, error.message);
      }
    }

    async function modifyPlan() {
      if (uiFlags.isButtonLoading) {
        return;
      }

      try {
        uiFlags.isButtonLoading = true;
        await $store.dispatch('Subscription/modify');
        uiFlags.isVisible = false;
        context.emit('modified', {
          plan: newPlan.value,
        });
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isButtonLoading = false;
      }
    }
  },
};
</script>

<style lang="scss">
</style>
