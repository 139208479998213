<template>
  <ElTable
    :data="subscriptionItems"
    class="mt-2 mb-4"
  >
    <ElTableColumn
      label="Items"
      min-width="250"
      prop="item"
    />
    <ElTableColumn
      label="Amount"
      prop="amount"
      class-name="amount-column"
      label-class-name="amount-column"
    />
  </ElTable>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'BillingStripeItemsTable',
  components: {},
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const subscriptionItems = ref([]);
    init();

    return {
      subscriptionItems,
    };

    async function init() {
      if (props.subscription.stripe_invoice) {
        const stripeInvoice = props.subscription.stripe_invoice;
        stripeInvoice.items.map((item) => subscriptionItems.value.push({
          item: item.title,
          amount: item.amount,
        }));
      }
    }
  },
};
</script>
<style>
.amount-column {
  text-align: end !important;
}
</style>
